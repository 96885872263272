@import '../../includes';

$status-padding: 15px;

.StatusBar-Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: calc($navigationHeight + $status-padding);

    &.centered {
        justify-content: center;
    }

    &.right {
        justify-content: right;
        right: 15px;
    }

    .StatusBar {
        width: 150px;
        max-width: 150px;
        padding: 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        background-color: $blackTransparent;
        border: 2px solid transparent;
        border-radius: 50px;

        &.transparent {
            background-color: #00000010; //transparent;
            opacity: 1;
            border: 2px solid #00000030;
        }

        .StatusIcon {
            display: flex;
            flex-direction: column;
            justify-content: center;

            svg {
                height: 32px;
                width: 32px;
                opacity: 1;
            }
        }
    }
}

.Camera-Button-Container {
    position: absolute;
    left: $status-padding;
    bottom: calc($navigationHeight + $status-padding);
    z-index: 10;

    .Camera-Button {
        border: none;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background-color: $ucfGold;
        cursor: pointer;
        box-shadow: 0px 1px 3px $ucfBlack;
        display: flex;

        svg {
            height: 36px;
            width: 36px;
            position: absolute;
            top: 4px;
            left: 5px;
        }
    }
}

.extra-padding {
    .StatusBar-Container,
    .Camera-Button-Container {
        bottom: calc($navigationHeight + $extraPaddingHeight + $status-padding);
    }
}

.wide {
    .StatusBar-Container {
        width: calc(100% - $navigationWidth);
        bottom: $status-padding;
    }

    .Camera-Button-Container {
        position: absolute;
        left: calc($navigationWidth + 15px);
        bottom: $status-padding;
    }

    &.extra-padding {
        .StatusBar-Container,
        .Camera-Button-Container {
            bottom: calc($status-padding + $extraPaddingHeight);
        }
    }
}
