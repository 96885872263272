@import '../../includes';

.Loader {
    height: 31px;   // Same height as a button

    .Spinner {
        margin-right: 10px;

        svg {
            animation: spin 1s linear infinite;
        }
    }

    .Loader-text {
        text-transform: uppercase;
        font-size: .85em;
        font-weight: 600;
    }

    &.black {
        color: $ucfBlack;
        fill: $ucfBlack;
    }

    &.white {
        color: $ucfWhite;
        fill: $ucfWhite;
    }

    &.gold {
        color: $ucfGold;
        fill: $ucfGold;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
