@import './includes';

.App {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh; // Firefox doesn't accept dvh
  height: 100dvh;
  overflow: hidden;

  .Screen-header {
    background-color: $ucfGold;
    width: 100vw;
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-weight: 800;
    flex-shrink: 0;
    border-bottom: 1px solid $borderColor;
  }

  .FullScreen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .PrimaryScreen {
      height: $primaryHeightVh; // Firefox doesn't accept dvh
      max-height: $primaryHeightVh; // Firefox doesn't accept dvh
      height: $primaryHeight;
      max-height: $primaryHeight;
    }

    .FooterNav {
      border-top: 1px solid $borderColor;
      flex-shrink: 0;
      box-sizing: border-box;
      height: $navigationHeight;
    }
  }

  .FullContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .PrimaryScreen {
      height: $primaryHeightVh; // Firefox doesn't accept dvh
      max-height: $primaryHeightVh; // Firefox doesn't accept dvh
      height: $primaryHeight;
      max-height: $primaryHeight;
    }

    .FooterNav {
      border-top: 1px solid $borderColor;
      flex-shrink: 0;
      box-sizing: border-box;
      height: $navigationHeight;
    }
  }

  // The extra padding is to accommodate the line that appears at the bottom of the screen
  // for iPhones that have the app installed as a PWA.

  &.extra-padding {

    .FullScreen {
      
      .PrimaryScreen {
        height: $primaryHeightExtraPaddingVh; // Firefox doesn't accept dvh
        max-height: $primaryHeightExtraPaddingVh; // Firefox doesn't accept dvh
        height: $primaryHeightExtraPadding;
        max-height: $primaryHeightExtraPadding;
      }

      .FooterNav {
        height: calc($navigationHeight + $extraPaddingHeight);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .Footer-button {
          height: $navigationHeight;
        }
      }
    }
  }

  &.wide {

    .FullScreen {
      flex-direction: row-reverse;

      .PrimaryScreen {
        height: 100%;
        max-height: 100%;
      }

      .FooterNav {
        border-top: none;
        filter: drop-shadow(-2px 1px 5px #000000);
        height: 100vh;
        height: 100dvh;
        display: flex;
        flex-direction: column;

        .Footer-button {
          height: auto;
        }
      }
    }
  }
}