@import '../../includes';

$status-padding: 15px;
$slider-height: 60px;
$zoom-height: calc($slider-height + 112px);

.Zoom-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: $navigationHeight + $status-padding;
    right: $status-padding;
    background-color: $blackTransparent;
    padding: 0px 0px;
    border-radius: 50px;
    z-index: 10;

    .Zoom-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px 0px;
        &:first-child {
            padding: 8px 0px 5px 0px;
        }
        &:last-child {
            padding: 5px 0px 8px 0px;
        }
    }

    .Zoom-selector-container {
        height: $slider-height;
        width: 46px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid #FFFFFF80;
        border-bottom: 1px solid #FFFFFF80;
        padding: 10px 0px;
    }

    input[type=range] {
        appearance: none;
        -webkit-appearance: none;
        background: transparent;
        width: $slider-height;
        transform: rotate(270deg);
        
        &::-webkit-slider-thumb {
            appearance: none;
            -webkit-appearance: none;
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-color: $ucfGold;
            box-shadow: 0px 0px 2px $ucfBlack;
            cursor: pointer;
            margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        }

        // Firefox
        &::-moz-range-thumb {
            appearance: none;
            -webkit-appearance: none;
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-color: $ucfGold;
            box-shadow: 0px 0px 2px $ucfBlack;
            cursor: pointer;
        }

        &::-webkit-slider-runnable-track {
            transition: $darkModeTransition;
            width: 100%;
            height: 10px;
            background-color: $ucfWhite;
            border-radius: 5px;
        }

        &::-moz-range-track {
            transition: $darkModeTransition;
            width: 100%;
            height: 10px;
            background-color: $ucfWhite;
            border-radius: 5px;
        }
    }
}

.extra-padding {
    .Zoom-container {
        bottom: calc($navigationHeight + $extraPaddingHeight + $status-padding);
    }
}

.wide {
    .Zoom-container {
        // If we want to change the zoom slider to center vertically, we can use this:
        // top: calc(50% - ($zoom-height / 2) - ((($iconContainerHeight + $status-padding) - $cameraMessageHeight) / 2));
        // bottom: auto;
        bottom: $status-padding;
    }
}

.wide.extra-padding {
    .Zoom-container {
        // If we want to change the zoom slider to center vertically, we can use this:
        // top: max(calc(50% - ($zoom-height / 2) - ((($iconContainerHeight + $status-padding + $extraPaddingHeight) - $cameraMessageHeight) / 2)), calc($cameraMessageHeight + $status-padding));
        // bottom: auto;
        bottom: calc($status-padding + $extraPaddingHeight);
    }
}
