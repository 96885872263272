@import '../../includes';

.Login-container {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    background-color: $ucfGold;
}

.Login-Button-container {
    position: absolute;
    bottom: calc(32px + 1em);
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.Login-body-container {
    margin:auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;

    .Login-Error {
        margin-top: 1em;
        text-align: center;
        font-size: .9em;
        height: 1em;
        color: darkred;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

.Login-body {
    margin:auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    .Login-logo {
        margin: auto;
        width: 175px;
        max-width: 50vh;
    }

    .Login-text {
        margin: auto;
        margin-top: 32px;

        /* The font size for UCF and Here is absolute px instead of em so it doesn't change with font size adjustments */
        .Login-UCF {
            font-size: 68px;
            font-weight: 900;
            color: $ucfWhite;
            line-height: .5em;
            text-align: center;
        }

        .Login-Here {
            font-size: 64px;
            font-style: italic;
            font-weight: 400;
            color: $ucfBlack;
            text-align: center;
            transform: scale(1, .95);
        }
    }

}

.Login-Version {
    position: absolute;
    bottom: 1em;
    font-size: .75em;
    font-weight: 600;
    color: $ucfBlack;
}

.extra-padding {
    .Login-Button-container {
        bottom: calc(2.5em + $extraPaddingHeight);
    }

    .Login-Version {
        bottom: calc(1em + $extraPaddingHeight);
    }
}


.wide {
    .Login-body-container {
        padding-bottom: 2.5em;

        .Login-body {
            flex-direction: row;

            .Login-logo {
                margin-right: 16px;
            }
            .Login-text {
                margin-left: 16px;
            }
        }
    }

    .Login-Button-container {
        bottom: 1em;
    }

    .Login-Version {
        bottom: 2em;
        right: 1em;
    }

    &.extra-padding {
        .Login-Button-container {
            bottom: calc(1em + $extraPaddingHeight);
        }
    
        .Login-Version {
            bottom: calc(2em + $extraPaddingHeight);
        }
    }
}