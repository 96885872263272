@import '../../includes';

.CheckIn-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    min-height: 100%;

    &.lifted {
      margin-top: -$cameraMessageHeight;
    }

    section {
      height: 100%;

      > div {
        height: 100%;
        padding-top: 0 !important;

        video {
            object-fit: cover;
        }
      }
    }
}

.Camera-Message-Container {
  position: relative;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: $blackTransparent;
  height: $cameraMessageHeight;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Camera-Message {
    text-align: center;
    font-size: .9em;
    font-weight: 600;
    color: $ucfWhite;
    padding: 4px 10px;

  }
}

.Camera-Focus {
  position: absolute;
  $focus-border: 6px solid $ucfWhite;
  opacity: .75;

  .Focus-Corner {
    position: absolute;
    width: 30%;
    height: 30%;
  }

  .Camera-Focus-TL {
    top: 0;
    left: 0;
    border-top: $focus-border;
    border-left: $focus-border;
  }

  .Camera-Focus-TR {
    top: 0;
    right: 0;
    border-top: $focus-border;
    border-right: $focus-border;
  }

  .Camera-Focus-BL {
    bottom: 0;
    left: 0;
    border-bottom: $focus-border;
    border-left: $focus-border;
  }

  .Camera-Focus-BR {
    bottom: 0;
    right: 0;
    border-bottom: $focus-border;
    border-right: $focus-border;
  }
}

.hidden {
  display: none;
}


.tall {
  $unusedHeightTall: $cameraMessageHeight + $navigationHeight + 60;

  .Camera-Focus {
    width: 70vw;
    height: 70vw;
    top: calc($cameraMessageHeight + ((100vh - ($unusedHeightTall + 70vw)) * 0.5)); // Firefox doesn't accept dvh
    top: calc($cameraMessageHeight + ((100dvh - ($unusedHeightTall + 70vw)) * 0.5));
    left: 15vw;
  }

  &.extra-padding {
    .Camera-Focus {
      top: calc($cameraMessageHeight + ((100vh - ($unusedHeightTall + $extraPaddingHeight + 70vw)) * 0.5)); // Firefox doesn't accept dvh
      top: calc($cameraMessageHeight + ((100dvh - ($unusedHeightTall + $extraPaddingHeight + 70vw)) * 0.5));
    }
  }
}

.wide {

  $unusedHeightWide: $cameraMessageHeight + 60;

  .Camera-Focus {
    // Firefox doesn't accept dvh
    height: calc((100vh - $unusedHeightWide) * .90);
    width: calc((100vh - $unusedHeightWide) * .90);
    top: calc($cameraMessageHeight + ((100vh - $unusedHeightWide) *.05));
    left: calc((50vw + ($navigationWidth * .5) - ((100vh - $unusedHeightWide) * .45)));

    height: calc((100dvh - $unusedHeightWide) * .90);
    width: calc((100dvh - $unusedHeightWide) * .90);
    top: calc($cameraMessageHeight + ((100dvh - $unusedHeightWide) *.05));
    left: calc((50vw + ($navigationWidth * .5) - ((100dvh - $unusedHeightWide) * .45)));
  }

  &.extra-padding {
    $unusedHeightWidePadding: $cameraMessageHeight + $extraPaddingHeight + 60;

    .Camera-Focus {
      // Firefox doesn't accept dvh
      height: calc((100vh - $unusedHeightWidePadding) * .90);
      width: calc((100vh - $unusedHeightWidePadding) * .90);
      top: calc($cameraMessageHeight + ((100vh - $unusedHeightWidePadding) *.05));
      left: calc((50vw + ($navigationWidth * .5) - ((100vh - $unusedHeightWidePadding) * .45)));
  
      height: calc((100dvh - $unusedHeightWidePadding) * .90);
      width: calc((100dvh - $unusedHeightWidePadding) * .90);
      top: calc($cameraMessageHeight + ((100dvh - $unusedHeightWidePadding) *.05));
      left: calc((50vw + ($navigationWidth * .5) - ((100dvh - $unusedHeightWidePadding) * .45)));
    }
  
  }
}