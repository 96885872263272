@import '../../includes';

.Footer-container {
    transition: $darkModeTransition;
    display: flex;
    overflow: hidden;
    background-color: $ucfGold;
    justify-content: space-around;
    height: 100%;

    .Footer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .Footer-button {
        width: 30%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        border-top: 3px solid;
        border-color: $ucfGold;
        transition: border-color $transitionSpeed ease-in-out;

        .nav-content-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-around;
        }

        .Footer-icon {
            padding-top: 5px;
            width: 24px;
            height: 24px;
            margin: auto;
        }

        .Footer-text {
            transition: $darkModeTransition;
            color: $ucfBlack;
            font-size: .85em;
            padding-bottom: 5px;
            margin: auto;
            text-align: center;
        }

        &.active {
            font-weight: 800;
            border-top: 3px solid $ucfBlack;
        }
    }

    .icon {
        transition: fill $transitionSpeed ease-in-out;
        width: 24px;
        height: 24px;
        fill: $ucfBlack;
    }
}

.wide {

    .Footer-container {
        border-right: 1px solid $borderColor;
        width: $navigationWidth;
        max-width: $navigationWidth;

        .Footer-row {
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
        }

        .Footer-button {
            height: auto;
            min-width: 100%;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            border-top: none;
            border-right: 3px solid;
            border-color: $ucfGold;
            padding-top: 0.15em;
            margin-top: calc(5px + .25em);

            .Footer-text {
                margin-top: 5px;
            }

            &.active {
                font-weight: 800;
                border-top: none;
                border-color: $ucfBlack;
            }
        }
    }
}
