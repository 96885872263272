$ucfGold: #ffc904;
$ucfGoldBG: #fcc90740; // No Alpha is #FEF2CD, #3F330A in Dark Mode
$ucfBlack: #000000;
$ucfBlackBG: #333333;
$ucfWhite: #FFFFFF;
$borderColor: #999;
$darkModeWhite: #E1E1E1; // #C9C9C9;
$blackTransparent: #00000060;

$green: #005500;
$greenLight: #00BB00;
$blue: #0000FF;
$blueLight: #9999FF;
$orange: #A85A00;
$orangeLight: #F08000;
$red: #BA0000;
$redLight: #FF2424;

$transitionSpeed: .3s;
$darkModeTransition: color $transitionSpeed ease-in-out, background-color $transitionSpeed ease-in-out;

.App {
  transition: $darkModeTransition;
}

$navigationHeight: 64px;  // When in tall mode
$navigationWidth: 110px;  // When in widescreen mode

$iconContainerHeight: 46px;
$extraPaddingHeight: 20px;

$primaryHeight: calc(100dvh - $navigationHeight);
$primaryHeightVh: calc(100vh - $navigationHeight);

$primaryHeightExtraPadding: calc(100dvh - ($navigationHeight + $extraPaddingHeight));
$primaryHeightExtraPaddingVh: calc(100vh - ($navigationHeight + $extraPaddingHeight));

$cameraMessageHeight: 34px;

$contentWidth: 500px;

$tabletMinWidth: 768px;
$contentWidthTablet: 700px;

$laptopMinWidth: 1024px;
$contentWidthLaptop: 700px;

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,600;0,800;1,300&display=swap');

/************** LAYOUT CLASSES **************/

.flex-r,
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-c,
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex-grow {
  flex-grow: 1;
}

.flex-stiff {
  flex-shrink: 0;
}

.flex-space {
  justify-content: space-between;
}

.wide-content-wrapper {
  width: 100%;
  max-width: $contentWidth;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: $tabletMinWidth) {
    max-width: $contentWidthTablet;

  }

  @media only screen and (min-width: $laptopMinWidth) {
    max-width: $contentWidthLaptop;

  }
}

.button-rounded {
  transition: $darkModeTransition;
  text-transform: uppercase;
  text-align: center;
  min-width: 150px;
  max-width: 75%;
  margin: auto;
  font-size: .9em;
  border: none;
  padding: 0.5em 1.75em;
  border-radius: 100px;
  font-weight: 600;
  background-color: $ucfBlack;
  color: $ucfWhite;
  cursor: pointer;

  &.gold {
    color: $ucfBlack;
    background-color: $ucfGold;
  }

  &.green {
    background-color: $green;
  }
}

svg {
  &.white {
    fill: $ucfWhite;
  }

  &.gold {
    fill: $ucfGold;
  }

  &.green {
    fill: $green;
  }

  &.red {
    fill: $red;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.font-size-smaller {
  font-size: 11px;
}

.font-size-small {
  font-size: 13px;
}

.font-size-normal {
  font-size: 16px;
}

.font-size-large {
  font-size: 20px;
}

.font-size-larger {
  font-size: 24px;
}

.clickable {
  cursor: pointer;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.Reader-Instructions {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/****************************  DARK MODE STYLES  ****************************/

.darkMode {
  color: $darkModeWhite;
  background-color: $ucfBlack;

  .button-rounded {
    color: $ucfBlack;
    background-color: $ucfGold;
  }

  svg {
    &.green {
      fill: $greenLight;
    }

    &.red {
      fill: $redLight;
    }
  }

  .HeaderNav {
    background-color: $ucfBlackBG;
    color: $darkModeWhite;
  }

  .Loader {
    &.black {
      color: $darkModeWhite;
      fill: $darkModeWhite;
    }
  }

  .Login-container {
    background-color: $ucfBlack;

    .Login-body-container .Login-Error {
      color: #FF0000;
    }

    .Login-body .Login-text {
      .Login-UCF {
        color: $ucfGold;
      }

      .Login-Here {
        color: $darkModeWhite;
      }
    }

    .Login-Version {
      color: $darkModeWhite;
    }
  }

  .StatusBar-Container {
    .StatusBar.transparent {
      background-color: $ucfBlackBG;
      border: 2px solid $ucfBlackBG;
      opacity: 1;
    }
  }

  .Feedback-container {
    .text-title {
      &.red {
        color: $redLight;
      }

      &.green {
        color: $greenLight;
      }
    }
  }

  .Header-Button-container {
    color: $ucfGold;

    .Expand-Icon {
      fill: $ucfGold;
    }
  }

  .History-container {
    .List-Header {
      background-color: $ucfBlackBG;
      color: $darkModeWhite;
    }

    .Course-List-Item {

      &:nth-child(2n + 1) {
        background-color: $ucfBlackBG;
      }
    }

    .History-List-Item {

      &:nth-child(2n) {
        background-color: $ucfBlackBG;
      }

      .stateClass0 {
        // Present
        color: $greenLight;
      }

      .stateClass2,
      .stateClass3 {
        // Excused, Excluded
        color: $blueLight;
      }

      .stateClass4 {
        // Tardy
        color: $orangeLight;
      }

      .stateClass1 {
        // Absent
        color: $redLight;
      }
    }

    .Course-List-Item .icon {
      fill: $ucfGold;
    }
  }

  .Profile-container {

    .inline-icon svg {
      fill: $darkModeWhite;
    }

    a {
      color: $ucfGold;
    }

    select {
      background-color: $darkModeWhite;
      color: $ucfBlack;
    }

    .dropdown-icon {
      fill: $ucfBlack;
    }

    input[type=range]::-webkit-slider-runnable-track {
      background-color: $darkModeWhite;
    }

    input[type=range]::-moz-range-track {
      background-color: $darkModeWhite;
    }

    .toggle-switch-switch {
      background-color: $ucfBlack;
    }
  }

  .Footer-container {
    background-color: $ucfBlackBG;

    .icon {
      fill: $ucfGold;
    }

    .Footer-text {
      color: $ucfGold;
    }

    .Footer-button {
      border-color: $ucfBlackBG;

      &.active {
        border-color: $ucfGold;
      }
    }
  }
}
