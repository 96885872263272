@import '../../includes';

.History-List-Item {
    width: 100%;
    padding: 5px 8px;
    border-bottom: 1px solid $borderColor;
    box-sizing: border-box;

    &:nth-child(2n) {
        background-color: $ucfGoldBG;
    }

    .List-Item-Title {
        font-weight: bold;
        font-size: 1.1em;
    }

    .List-Item-Subtitle {
        font-size: .9em;
    }

    .List-Item-Subtext {
        margin-top: .5em;
        font-size: .8em;
        color: $borderColor;
    }

    .Expand-Icon {
        height: 32px;
    }

    .stateClass0 {  // Present
        color: $green;
    }

    .stateClass2, .stateClass3 {  // Excused, Excluded
        color: $blue;
    }

    .stateClass4 {  // Tardy
        color: $orange;
    }

    .stateClass1 {  // Absent
        color: $red;
    }
}

.ltr .History-Item-State {
    padding-left: 1em;
}

.rtl .History-Item-State {
    padding-right: 1em;
}