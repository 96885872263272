@import '../../includes';

.Header-Button-container {
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 2;
    height: 36px;
    display: flex;
    flex-direction: row;
    margin-top: calc(1em - 5px);
    border-radius: 50px;
    cursor: pointer;
    font-size: .9em;
    font-weight: 600;
    color: $ucfGold;

    .Expand-Icon.reversed {
        height: 2.25em;
        width: 2.25em;
        min-height: 2.25em;
        transform: rotate(180deg);
        fill: $ucfGold;
    }

    label {
        display: none;
    }   
}

.History-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Loader-container {
        width: 100%;
        text-align: center;
        margin-top: 3em;
    }

    .Button-container {
        position: relative;
        bottom: 0;
        margin-top: 1em;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .List-Header {
        position: sticky;
        top: 0;
        background-color: $ucfBlack;
        color: $ucfWhite;
        text-align: center;
        padding: 8px 2.5em;
        z-index: 1;
        border-bottom: 1px solid $borderColor;

        .List-Header-Title {
            font-weight: 800;
        }

        .List-Header-Subtitle {
            font-size: .8em;
            font-weight: 600;
            margin-top: 5px;
        }
    }
}


@media only screen and (min-width: $tabletMinWidth) {
    .Header-Button-container label {
        display: flex;
        width: 8em;
        max-width: 8em;

        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .History-container .List-Header {
        padding: 8px 11.25em;
    }

}

.wide {
    .Header-Button-container {
        left: calc($navigationWidth + 5px);
    }

    &.extra-padding {

        .History-container > .flex-c {
            margin-bottom: $extraPaddingHeight;
        }

        .Button-container {
            margin-bottom: calc(1em + $extraPaddingHeight);
        }
    }
}