@import '../../includes';

.Profile-container {
    display: flex;
    flex-grow: 1;
    text-align: center;
    overflow-y: auto;

    label {
        font-size: 1.25em;
        font-weight: bold;
    }

    .image-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 84px;
        height: 84px;

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 75%;
        }

        .profile-img {
            margin-right: 2em;
            border-radius: 50px;
            height: 50px;
            width: 50px;
            border: 2px solid $borderColor;
            object-fit: cover;
            box-sizing: border-box;
        }

        h2 {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1.25em;
        }
    }

    .Notice-container {
        padding: 1em .5em;
        font-size: .8em;
        max-height: auto;
        box-sizing: border-box;
        overflow-y: auto;
    }

    a {
        transition: $darkModeTransition;
        color: $blue;
    }

    .setting-container {
        margin: 1.5em 1.5em 0em 1.5em;

        .settingLabel {
            margin-right: 1em;
            text-align: left;
        }
    }

    .size-samples {
        margin: -2px 4px 0px 4px;
        .size-sample {
            font-weight: bold;
            width: 20px;
        }
    }

    @media screen and (min-height: 450px) {
        .image-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            height: auto;

            > div {
                flex-direction: row;
                width: 100%;
                max-width: 100%;
            }

            .profile-img {
                margin-right: 0px;
                height: 100px;
                width: 100px;
                border-radius: 100px;
            }

            h2 {
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 1.5em;
            }
        }

        .Notice-container {
            background-color: $ucfGoldBG;
            border: 2px solid $ucfGold;
            border-radius: .5em;
            padding: 1.5em 1em;
            margin-left: 1.5em;
            margin-right: 1.5em;
            overflow-y: auto;
            font-size: 1em;
        }
    }

    .inline-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-left: 5px;
        margin-right: 5px;

        svg {
            transition: fill $transitionSpeed ease-in-out;
            fill: $ucfBlack;
            margin-bottom: -2px;
            width: 1em;
            height: 1em;
        }
    }

    .Button-container {
        margin-top: 1.5em;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        position: initial;
        bottom: unset;
    }

    .version {
        font-size: .8em;
        font-weight: 800;
    }

    input[type=range] {
        appearance: none;
        -webkit-appearance: none;
        background: transparent;
        width: 130px;
        
        &::-webkit-slider-thumb {
            appearance: none;
            -webkit-appearance: none;
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-color: $ucfGold;
            box-shadow: 0px 0px 2px $ucfBlack;
            cursor: pointer;
            margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        }

        // Firefox
        &::-moz-range-thumb {
            appearance: none;
            -webkit-appearance: none;
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            background-color: $ucfGold;
            box-shadow: 0px 0px 2px $ucfBlack;
            cursor: pointer;
            // margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        }

        &::-webkit-slider-runnable-track {
            transition: $darkModeTransition;
            width: 100%;
            height: 10px;
            background-color: $ucfBlack;
            border-radius: 5px;
        }

        &::-moz-range-track {
            transition: $darkModeTransition;
            width: 100%;
            height: 10px;
            background-color: $ucfBlack;
            border-radius: 5px;
        }
    }

    select {
        appearance: none;
        font-size: 1em;
        padding: .25em 3em .25em 1em;
        background-color: black;
        color: white;
        font-family: inherit;
        margin-right: -2.5em;
        border: none;
        border-radius: .25em;
        transition: $darkModeTransition;
    }

    option {
        font-family: inherit;
    }

    .dropdown-icon {
        transition: fill $transitionSpeed ease-in-out;
        pointer-events: none;
        width: 2.5em;
        height: 1.5em;
        margin-top: 0.15em;
    }
}