@import '../../../includes';

$statusBarHeight: 75px;

.Feedback-container {
  width: 100%;
  padding: 1em 2em;
  box-sizing: border-box;
  text-align: center;

  .sans-status-bar {
    height: 100%;
    max-height: calc(100vh - ($statusBarHeight + 1em)); // Firefox doesn't accept dvh
    max-height: calc(100dvh - ($statusBarHeight + 1em));
    overflow: auto;
  }

  .text-title {
    font-size: 1.5em;
    font-weight: 800;
    margin-bottom: .75em;

    &.red {
      color: $red;
    }

    &.green {
      color: $green;
    }
  }

  .Notice-container {
    padding: 1em 1em;
    font-size: .8em;

    .text-item:not(:last-child) {
      margin-bottom: 1.75em;
    }
  }

  @media screen and (min-height: 400px) {
    .Notice-container {
        background-color: $ucfGoldBG;
        border: 2px solid $ucfGold;
        border-radius: 12px;
        box-sizing: border-box;
        margin-left: 1.5em;
        margin-right: 1.5em;
        overflow-y: auto;
        font-size: 1em;
        margin-top: 1.5em;
    }
  }

  .text-subtitle {
    border-bottom: 0px solid $borderColor;
    max-width: 124px;
    width: 125px;
    margin: auto;
    font-weight: 800;
    margin-bottom: .25em;
  }

  .button-rounded {
    margin-top: 2em;
    z-index: 3;
  }
}

.extra-padding {
  .sans-status-bar {
    max-height: calc(100vh - ($extraPaddingHeight + $statusBarHeight + 1em)); // Firefox doesn't accept dvh
    max-height: calc(100dvh - ($extraPaddingHeight + $statusBarHeight + 1em));
  }
}

.tall {
  .Feedback-container {
    .sans-status-bar {
      max-height: calc(100vh - ($navigationHeight + $statusBarHeight + 1em)); // Firefox doesn't accept dvh
      max-height: calc(100dvh - ($navigationHeight + $statusBarHeight + 1em));
    }
  }

  &.extra-padding {
    .Feedback-container {
      .sans-status-bar {
        max-height: calc(100vh - ($navigationHeight + $extraPaddingHeight + $statusBarHeight + 1em)); // Firefox doesn't accept dvh
        max-height: calc(100dvh - ($navigationHeight + $extraPaddingHeight + $statusBarHeight + 1em));
      }
    }
  }
}