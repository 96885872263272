@import '../../includes';

.Course-List-Item {
    width: 100%;
    padding: 5px 8px;
    border-bottom: 1px solid $borderColor;
    box-sizing: border-box;
    cursor: pointer;

    &:nth-child(2n + 1) {
        background-color: $ucfGoldBG;
    }

    .List-Item-Title {
        font-weight: bold;
        font-size: 1.1em;
    }

    .List-Item-Subtitle {
        font-size: .9em;
    }

    .icon {
        transition: fill $transitionSpeed ease-in-out;
        height: 2em;
        fill: $ucfBlack;
    }
}
